$ctrl-cont-width-land: 100vw;
$ctrl-cont-width-port: 45vw;

.ar-count {
  opacity: 0;
  pointer-events: none;
}

#count-container {
  position: absolute;
  text-outline: 0;
  left: 0;
  // background: red;
}

#ar-ui-container button {
  width: 100%;
  height: 100%;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important ; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // background: blue;
}

#canvas {
  touch-action: none;
}

#ar-ui-container button svg {
  width: 100%;
  height: 100%;
}

#ar-ui-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  // background: orange;
}

// UI containers
#ui-top {
  position: absolute;
  top: 0;
  left: 0;
  width: $ctrl-cont-width-land;
  height: auto;
  // background: green;
}

#ui-mid {
  position: absolute;
  top: 40vh;
  right: 0;
  width: $ctrl-cont-width-land;
  // background: red;
  height: auto;
}

#ui-lower {
  position: absolute;
  left: 0;
  bottom: 0;
  width: $ctrl-cont-width-land;
  // background:pink;
  height: auto;
}

// Bottom Controls
#ctrl-container {
  width: $ctrl-cont-width-land;
  height: calc($ctrl-cont-width-land / 2.75);
  margin: auto;
  // background:green
}

#ctrl-multi {
  width: calc($ctrl-cont-width-land / 100) * 13.63;
  height: calc($ctrl-cont-width-land / 100) * 13.63;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' width= '100%25' height='100%25' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 150 150' style='enable-background:new 0 0 150 150;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cpolyline class='st0' points='65.82,86.95 58.07,78.05 58.03,78 24.37,116.69 45.57,116.69 70.55,116.69 91.69,116.69 73.71,96.02 '/%3E%3Cpath class='st1' d='M45.57,116.69c0,6.9,5.59,12.5,12.5,12.5c6.89,0,12.49-5.59,12.49-12.5'/%3E%3Cline class='st1' x1='58.08' y1='23.58' x2='58.08' y2='79.26'/%3E%3Cpolygon class='st0' points='125.26,96.02 73.71,96.02 65.82,86.95 91.59,57.33 91.63,57.38 '/%3E%3Cpath class='st1' d='M79.13,96.02c0,6.9,5.59,12.5,12.5,12.5c6.89,0,12.49-5.59,12.49-12.5'/%3E%3Cline class='st1' x1='91.64' y1='23.48' x2='91.64' y2='58.58'/%3E%3C/g%3E%3C/svg%3E%0A");
  float: right;
}

// Top UI btns
#ctrl-hide {
  display: inline-block;
  float: left;
  width: calc($ctrl-cont-width-land / 100) * 13.63;
  height: calc($ctrl-cont-width-land / 100) * 13.63;
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' width='100%25' height='100%25' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 150'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7B fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:8px;%7D%3C/style%3E%3C/defs%3E%3Ccircle class='cls-1' cx='75' cy='75.35' r='14.95'/%3E%3Cpath class='cls-1' d='M120,75S99.85,99.23,75,99.23,30,75,30,75,50.15,50.71,75,50.71,120,75,120,75Z'/%3E%3C/svg%3E");
}

#ctrl-exit {
  display: inline-block;
  padding: 0;
  margin: 0;
  float: right;
  width: calc($ctrl-cont-width-land / 100) * 13.63;
  height: calc($ctrl-cont-width-land / 100) * 13.63;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg width='100%25' height='100%25' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 151 150' style='enable-background:new 0 0 151 150;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cline class='st0' x1='49.5' y1='49.5' x2='100' y2='100'/%3E%3Cline class='st0' x1='100' y1='49.5' x2='49.5' y2='100'/%3E%3C/svg%3E%0A");
  pointer-events: none !important;
}

#ctrl-row-1 {
  width: 100%;
  height: 37.5%;
}

.ctrl-gap-lg {
  display: inline-block;
  float: left;
  width: 13.63%;
  height: 100%;
}
.ctrl-gap-sm {
  display: inline-block;
  float: left;
  width: 9.09%;
  height: 100%;
}

.ctrl-gap-sq {
  display: inline-block;
  float: left;
  width: 9.09%;
  height: 100%;
}

.ctrl-gap-mid {
  display: inline-block;
  float: left;
  width: 6.5%;
  height: 100%;
}

.ctrl-arw-up {
  display: inline-block;
  float: left;
  width: 9.09%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg width='100%25' height='100%25' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 150' style='enable-background:new 0 0 100 150;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='5,75 51,30 95,75 '/%3E%3Cline class='st1' x1='50.5' y1='60.5' x2='50.5' y2='140.5'/%3E%3C/svg%3E%0A");
}

.ctrl-arw-curveup {
  display: inline-block;
  float: left;
  width: 9.09%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 150'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7B fill:none;stroke:%23fff;stroke-linecap:round;stroke-width:8px;%7D.cls-1%7Bstroke-miterlimit:10;%7D.cls-2%7Bstroke-linejoin:round;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M31,51c29,19,49,50,60,90'/%3E%3Cpolyline class='cls-2' points='10 95 10 30 75 30'/%3E%3C/svg%3E");
}

#ctrl-row-2 {
  width: 100%;
  height: 25%;
}

.ctrl-arw-left {
  display: inline-block;
  float: left;
  height: 100%;
  width: 13.63%;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg width='100%25' height='100%25' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 150 100' style='enable-background:new 0 0 150 100;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='75,95 30,50 76,6 '/%3E%3Cline class='st1' x1='60' y1='50' x2='140' y2='50'/%3E%3C/svg%3E%0A");
}

.ctrl-arw-right {
  display: inline-block;
  float: left;
  height: 100%;
  width: 13.63%;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg width='100%25' height='100%25' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 150 100' style='enable-background:new 0 0 150 100;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='75.5,5.5 119.5,49.5 75.5,95.5 '/%3E%3Cline class='st1' x1='90.5' y1='50.5' x2='10.5' y2='50.5'/%3E%3C/svg%3E%0A");
}

#ctrl-row-3 {
  width: 100%;
  height: 37.5%;
}

.ctrl-arw-down {
  display: inline-block;
  float: left;
  width: 9.09%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 150'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7B fill:none;stroke:%23fff;stroke-linecap:round;stroke-width:8px;%7D.cls-1%7Bstroke-linejoin:round;%7D.cls-2%7Bstroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='95 75 50 121 5 75'/%3E%3Cline class='cls-2' x1='50' y1='90' x2='50' y2='10'/%3E%3C/svg%3E");
}

.ctrl-arw-curvedown {
  display: inline-block;
  float: left;
  width: 9.09%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg width='100%25' height='100%25' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 150' style='enable-background:new 0 0 100 150;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;%7D .st1%7B fill:none;stroke:%23FFFFFF;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st0' d='M29.5,99.5c30-19,51-50,61-89'/%3E%3Cpolyline class='st1' points='11,56 11,121 76,121 '/%3E%3C/svg%3E ");
}

#ctrl-title-row {
  width: 100%;
  height: 20px;
}

.ctrl-title {
  display: inline-block;
  height: 100%;
  width: 46.5%;
  color: white;
  text-align: center;
}

.on {
  opacity: 1;
  pointer-events: auto;
}

.off {
  opacity: 0;
  pointer-events: none;
}

/* Landscape */
@media screen and (orientation: landscape) {
  #ctrl-container {
    width: $ctrl-cont-width-port;
    height: calc($ctrl-cont-width-port / 2.75);
  }

  #ctrl-multi,
  #ctrl-hide,
  #ctrl-exit {
    width: calc($ctrl-cont-width-port / 100) * 13.63;
    height: calc($ctrl-cont-width-port / 100) * 13.63;
  }
}
