	/*
  	Flaticon icon font: Flaticon
  	Creation date: 06/01/2020 02:43
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-traffic-light:before { content: "\f100"; }
.flaticon-traffic-light-1:before { content: "\f101"; }
.flaticon-lamp-post:before { content: "\f102"; }
.flaticon-lighthouse:before { content: "\f103"; }
.flaticon-string-lights:before { content: "\f104"; }
.flaticon-warning:before { content: "\f105"; }
.flaticon-match:before { content: "\f106"; }
.flaticon-light-bulb:before { content: "\f107"; }
.flaticon-lamp:before { content: "\f108"; }
.flaticon-light-bulb-1:before { content: "\f109"; }
.flaticon-light:before { content: "\f10a"; }
.flaticon-lamp-1:before { content: "\f10b"; }
.flaticon-light-bulb-2:before { content: "\f10c"; }
.flaticon-lamp-2:before { content: "\f10d"; }
.flaticon-traffic-light-2:before { content: "\f10e"; }
.flaticon-light-1:before { content: "\f10f"; }
.flaticon-lamp-3:before { content: "\f110"; }
.flaticon-lamp-4:before { content: "\f111"; }
.flaticon-light-2:before { content: "\f112"; }
.flaticon-candles:before { content: "\f113"; }
.flaticon-traffic-light-3:before { content: "\f114"; }
.flaticon-light-bulb-3:before { content: "\f115"; }
.flaticon-flashlight:before { content: "\f116"; }
.flaticon-lamp-5:before { content: "\f117"; }
.flaticon-warning-1:before { content: "\f118"; }
.flaticon-lamp-6:before { content: "\f119"; }
.flaticon-candles-1:before { content: "\f11a"; }
.flaticon-lighter:before { content: "\f11b"; }
.flaticon-lantern:before { content: "\f11c"; }
.flaticon-lantern-1:before { content: "\f11d"; }
.flaticon-lamp-7:before { content: "\f11e"; }
.flaticon-lamp-8:before { content: "\f11f"; }
.flaticon-light-bulb-4:before { content: "\f120"; }
.flaticon-light-bulb-5:before { content: "\f121"; }
.flaticon-lamp-9:before { content: "\f122"; }
.flaticon-lamp-10:before { content: "\f123"; }
.flaticon-light-bulb-6:before { content: "\f124"; }
.flaticon-lamp-11:before { content: "\f125"; }
.flaticon-lamp-12:before { content: "\f126"; }
.flaticon-bulb:before { content: "\f127"; }
.flaticon-lamppost:before { content: "\f128"; }
.flaticon-spotlight:before { content: "\f129"; }
.flaticon-fluorescent:before { content: "\f12a"; }
.flaticon-lamp-13:before { content: "\f12b"; }
.flaticon-lamp-14:before { content: "\f12c"; }
.flaticon-traffic-light-4:before { content: "\f12d"; }
.flaticon-lamp-15:before { content: "\f12e"; }
.flaticon-spotlight-1:before { content: "\f12f"; }
.flaticon-light-3:before { content: "\f130"; }
.flaticon-traffic-light-5:before { content: "\f131"; }
.flaticon-lamppost-1:before { content: "\f132"; }
.flaticon-lamppost-2:before { content: "\f133"; }
.flaticon-lamp-16:before { content: "\f134"; }
.flaticon-lamp-17:before { content: "\f135"; }
.flaticon-candelabra:before { content: "\f136"; }
.flaticon-flashlight-1:before { content: "\f137"; }
.flaticon-traffic-light-6:before { content: "\f138"; }
.flaticon-candlelamp:before { content: "\f139"; }
.flaticon-candelabra-1:before { content: "\f13a"; }
.flaticon-light-4:before { content: "\f13b"; }
.flaticon-bulb-1:before { content: "\f13c"; }
.flaticon-bulb-2:before { content: "\f13d"; }
.flaticon-bulb-3:before { content: "\f13e"; }
.flaticon-lamppost-3:before { content: "\f13f"; }
.flaticon-lamp-18:before { content: "\f140"; }
.flaticon-bulb-4:before { content: "\f141"; }
.flaticon-lantern-2:before { content: "\f142"; }
.flaticon-flashlight-2:before { content: "\f143"; }
.flaticon-lamp-19:before { content: "\f144"; }
.flaticon-lamp-20:before { content: "\f145"; }
.flaticon-spotlight-2:before { content: "\f146"; }
.flaticon-bulb-5:before { content: "\f147"; }