.donut-3d-cont{
    position: absolute;
    top:0;
    width: inherit;
    height:inherit;
    // width: 100%;
    // height: 100%;
    display:flex;
    align-items: center;
    justify-content:'center';
  }

  .donut-3d {
    position: absolute;
    top:45%;
    left: 45%;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border-width:  0.3rem !important;
    border-style: solid !important;
    border-color:  #000 !important;
    border-top-color: grey !important;
    animation: 1.5s spin infinite linear;
    z-index: 5000;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
